/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetMessagesResponse,
  SendMessageResponse,
  SendTextMessageRequest,
} from '../models/index';
import {
    GetMessagesResponseFromJSON,
    GetMessagesResponseToJSON,
    SendMessageResponseFromJSON,
    SendMessageResponseToJSON,
    SendTextMessageRequestFromJSON,
    SendTextMessageRequestToJSON,
} from '../models/index';

export interface AcknowledgeMessageRequest {
    id: number;
}

export interface GetMessagesRequest {
    since?: Date;
}

export interface PostMessageRequest {
    sendTextMessageRequest: SendTextMessageRequest;
}

/**
 * 
 */
export class MessagesApi extends runtime.BaseAPI {

    /**
     */
    async acknowledgeMessageRaw(requestParameters: AcknowledgeMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling acknowledgeMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acknowledgeMessage(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acknowledgeMessageRaw({ id: id }, initOverrides);
    }

    /**
     */
    async getMessagesRaw(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMessagesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['since'] != null) {
            queryParameters['since'] = (requestParameters['since'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getMessages(since?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMessagesResponse> {
        const response = await this.getMessagesRaw({ since: since }, initOverrides);
        return await response.value();
    }

    /**
     */
    async postMessageRaw(requestParameters: PostMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendMessageResponse>> {
        if (requestParameters['sendTextMessageRequest'] == null) {
            throw new runtime.RequiredError(
                'sendTextMessageRequest',
                'Required parameter "sendTextMessageRequest" was null or undefined when calling postMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendTextMessageRequestToJSON(requestParameters['sendTextMessageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async postMessage(sendTextMessageRequest: SendTextMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendMessageResponse> {
        const response = await this.postMessageRaw({ sendTextMessageRequest: sendTextMessageRequest }, initOverrides);
        return await response.value();
    }

}
