/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePresignedPutUrlResponse
 */
export interface CreatePresignedPutUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePresignedPutUrlResponse
     */
    putUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePresignedPutUrlResponse
     */
    getUrl: string;
}

/**
 * Check if a given object implements the CreatePresignedPutUrlResponse interface.
 */
export function instanceOfCreatePresignedPutUrlResponse(value: object): value is CreatePresignedPutUrlResponse {
    if (!('putUrl' in value) || value['putUrl'] === undefined) return false;
    if (!('getUrl' in value) || value['getUrl'] === undefined) return false;
    return true;
}

export function CreatePresignedPutUrlResponseFromJSON(json: any): CreatePresignedPutUrlResponse {
    return CreatePresignedPutUrlResponseFromJSONTyped(json, false);
}

export function CreatePresignedPutUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePresignedPutUrlResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'putUrl': json['putUrl'],
        'getUrl': json['getUrl'],
    };
}

export function CreatePresignedPutUrlResponseToJSON(value?: CreatePresignedPutUrlResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'putUrl': value['putUrl'],
        'getUrl': value['getUrl'],
    };
}

