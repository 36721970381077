import { useEffect } from 'react'
import { useBlocker } from 'react-router-dom'

export const useConfirmBeforeLeaving = (shouldBlock: boolean) => {
  const blocker = useBlocker(shouldBlock)

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (
        window.confirm(
          'Vous avez des modifications non-enregistrées. Êtes-vous sûr(e) de vouloir quitter la page actuelle ?',
        )
      ) {
        blocker.proceed()
      }
    }
  }, [blocker])

  useEffect(() => {
    function warnBeforeLeaving(e: BeforeUnloadEvent) {
      if (shouldBlock) {
        e.preventDefault()
      }
    }

    window.addEventListener('beforeunload', warnBeforeLeaving)
    return () => window.removeEventListener('beforeunload', warnBeforeLeaving)
  }, [shouldBlock])
}
