import { createBrowserRouter } from 'react-router-dom'
import { HomeConfigurationsPage } from './router/configurations/HomeConfigurationsPage'
import {
  ConversationHelpFrame,
  ConversationsPage,
  ConversationThreadFrame,
  GroupMessageFrame,
} from './router/conversations/ConversationsPage'
import { DashboardPage } from './router/DashboardPage'
import { HelloPage } from './router/hello/HelloPage'
import { LoginPage } from './router/login/LoginPage'
import { ResetPasswordPage } from './router/login/ResetPasswordPage'
import { QrcodePage } from './router/qrcode/QrcodePage'
import { UserAccountsPage } from './router/users/UserAccountsPage'

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/nouveau-mot-de-passe',
    element: <ResetPasswordPage />,
  },
  {
    path: '/',
    element: <DashboardPage />,
    children: [
      {
        path: '',
        element: <HelloPage />,
      },
      {
        path: 'bonjour',
        element: <HelloPage />,
      },
      {
        path: 'conversations',
        element: <ConversationsPage />,
        children: [
          {
            path: '',
            element: <ConversationHelpFrame />,
          },
          {
            path: ':conversationId',
            element: <ConversationThreadFrame />,
          },
          {
            path: 'nouveau-message',
            element: <GroupMessageFrame />,
          },
        ],
      },
      {
        path: 'utilisateurs',
        element: <UserAccountsPage />,
      },
      {
        path: 'configurations',
        element: <HomeConfigurationsPage />,
      },
      {
        path: 'qrcode',
        element: <QrcodePage />,
      },
    ],
  },
])
