import { Paper, Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { HomeConfigurationDto } from '../../api-client'
import { useAlertService } from '../../lib/alerts'
import { useHomeApi } from '../../lib/api-clients'
import { HomeConfigurationsAndUsersTable } from './HomeConfigurationsAndUsersTable'
import { HomeConfigurationsTable } from './HomeConfigurationsTable'

export function HomeConfigurationsPage() {
  const alerts = useAlertService()
  const homeApi = useHomeApi()

  const [homeConfigurations, setHomeConfigurations] = useState<HomeConfigurationDto[]>([])

  const fetchHomeConfigurations = useCallback(async () => {
    try {
      const response = await homeApi.getHomeConfigurations()
      setHomeConfigurations(response.configurations)
    } catch (error) {
      alerts.error('Erreur lors du chargement des configurations. Veuillez réessayer.')
      console.error('Error fetching data:', error)
    }
  }, [alerts, homeApi])

  useEffect(() => {
    fetchHomeConfigurations()
  }, [fetchHomeConfigurations])

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} padding={2} height="100%">
      <Paper sx={{ flex: 1 }}>
        <HomeConfigurationsTable
          configurations={homeConfigurations}
          refreshHomeConfigurations={fetchHomeConfigurations}
        />
      </Paper>
      <Paper sx={{ flex: 1 }}>
        <HomeConfigurationsAndUsersTable configurations={homeConfigurations} />
      </Paper>
    </Stack>
  )
}
