/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetDtoAction } from './WidgetDtoAction';
import {
    WidgetDtoActionFromJSON,
    WidgetDtoActionFromJSONTyped,
    WidgetDtoActionToJSON,
} from './WidgetDtoAction';

/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {WidgetDtoAction}
     * @memberof WidgetDto
     */
    action: WidgetDtoAction;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    icon?: string;
}

/**
 * Check if a given object implements the WidgetDto interface.
 */
export function instanceOfWidgetDto(value: object): value is WidgetDto {
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function WidgetDtoFromJSON(json: any): WidgetDto {
    return WidgetDtoFromJSONTyped(json, false);
}

export function WidgetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'action': WidgetDtoActionFromJSON(json['action']),
        'label': json['label'],
        'icon': json['icon'] == null ? undefined : json['icon'],
    };
}

export function WidgetDtoToJSON(value?: WidgetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': WidgetDtoActionToJSON(value['action']),
        'label': value['label'],
        'icon': value['icon'],
    };
}

