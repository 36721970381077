/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignHomeConfigurationRequest,
  CreateOrUpdateHomeConfigurationRequest,
  CreateOrUpdateHomeConfigurationResponse,
  GetHomeConfigurationsResponse,
  HomeResponse,
} from '../models/index';
import {
    AssignHomeConfigurationRequestFromJSON,
    AssignHomeConfigurationRequestToJSON,
    CreateOrUpdateHomeConfigurationRequestFromJSON,
    CreateOrUpdateHomeConfigurationRequestToJSON,
    CreateOrUpdateHomeConfigurationResponseFromJSON,
    CreateOrUpdateHomeConfigurationResponseToJSON,
    GetHomeConfigurationsResponseFromJSON,
    GetHomeConfigurationsResponseToJSON,
    HomeResponseFromJSON,
    HomeResponseToJSON,
} from '../models/index';

export interface AssignAccountRequest {
    id: number;
    assignHomeConfigurationRequest: AssignHomeConfigurationRequest;
}

export interface CreateHomeConfigurationRequest {
    createOrUpdateHomeConfigurationRequest: CreateOrUpdateHomeConfigurationRequest;
}

export interface DeleteHomeConfigurationRequest {
    id: number;
}

export interface MarkHomeConfigurationAsDefaultRequest {
    id: number;
}

export interface UpdateHomeConfigurationRequest {
    id: number;
    createOrUpdateHomeConfigurationRequest: CreateOrUpdateHomeConfigurationRequest;
}

/**
 * 
 */
export class HomeApi extends runtime.BaseAPI {

    /**
     */
    async assignAccountRaw(requestParameters: AssignAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assignAccount().'
            );
        }

        if (requestParameters['assignHomeConfigurationRequest'] == null) {
            throw new runtime.RequiredError(
                'assignHomeConfigurationRequest',
                'Required parameter "assignHomeConfigurationRequest" was null or undefined when calling assignAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home/configuration/{id}/assign-account`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignHomeConfigurationRequestToJSON(requestParameters['assignHomeConfigurationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignAccount(id: number, assignHomeConfigurationRequest: AssignHomeConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignAccountRaw({ id: id, assignHomeConfigurationRequest: assignHomeConfigurationRequest }, initOverrides);
    }

    /**
     */
    async createHomeConfigurationRaw(requestParameters: CreateHomeConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrUpdateHomeConfigurationResponse>> {
        if (requestParameters['createOrUpdateHomeConfigurationRequest'] == null) {
            throw new runtime.RequiredError(
                'createOrUpdateHomeConfigurationRequest',
                'Required parameter "createOrUpdateHomeConfigurationRequest" was null or undefined when calling createHomeConfiguration().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home/configuration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateHomeConfigurationRequestToJSON(requestParameters['createOrUpdateHomeConfigurationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrUpdateHomeConfigurationResponseFromJSON(jsonValue));
    }

    /**
     */
    async createHomeConfiguration(createOrUpdateHomeConfigurationRequest: CreateOrUpdateHomeConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrUpdateHomeConfigurationResponse> {
        const response = await this.createHomeConfigurationRaw({ createOrUpdateHomeConfigurationRequest: createOrUpdateHomeConfigurationRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteHomeConfigurationRaw(requestParameters: DeleteHomeConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteHomeConfiguration().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home/configuration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteHomeConfiguration(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteHomeConfigurationRaw({ id: id }, initOverrides);
    }

    /**
     */
    async getHomeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HomeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HomeResponseFromJSON(jsonValue));
    }

    /**
     */
    async getHome(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HomeResponse> {
        const response = await this.getHomeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getHomeConfigurationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetHomeConfigurationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home/configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHomeConfigurationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getHomeConfigurations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetHomeConfigurationsResponse> {
        const response = await this.getHomeConfigurationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async markHomeConfigurationAsDefaultRaw(requestParameters: MarkHomeConfigurationAsDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling markHomeConfigurationAsDefault().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home/configuration/{id}/mark-as-default`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markHomeConfigurationAsDefault(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markHomeConfigurationAsDefaultRaw({ id: id }, initOverrides);
    }

    /**
     */
    async updateHomeConfigurationRaw(requestParameters: UpdateHomeConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrUpdateHomeConfigurationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateHomeConfiguration().'
            );
        }

        if (requestParameters['createOrUpdateHomeConfigurationRequest'] == null) {
            throw new runtime.RequiredError(
                'createOrUpdateHomeConfigurationRequest',
                'Required parameter "createOrUpdateHomeConfigurationRequest" was null or undefined when calling updateHomeConfiguration().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/home/configuration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateHomeConfigurationRequestToJSON(requestParameters['createOrUpdateHomeConfigurationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrUpdateHomeConfigurationResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateHomeConfiguration(id: number, createOrUpdateHomeConfigurationRequest: CreateOrUpdateHomeConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrUpdateHomeConfigurationResponse> {
        const response = await this.updateHomeConfigurationRaw({ id: id, createOrUpdateHomeConfigurationRequest: createOrUpdateHomeConfigurationRequest }, initOverrides);
        return await response.value();
    }

}
