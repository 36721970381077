/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';

/**
 * 
 * @export
 * @interface SectionDto
 */
export interface SectionDto {
    /**
     * 
     * @type {string}
     * @memberof SectionDto
     */
    title?: string;
    /**
     * 
     * @type {Array<GroupDto>}
     * @memberof SectionDto
     */
    groups: Array<GroupDto>;
}

/**
 * Check if a given object implements the SectionDto interface.
 */
export function instanceOfSectionDto(value: object): value is SectionDto {
    if (!('groups' in value) || value['groups'] === undefined) return false;
    return true;
}

export function SectionDtoFromJSON(json: any): SectionDto {
    return SectionDtoFromJSONTyped(json, false);
}

export function SectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'groups': ((json['groups'] as Array<any>).map(GroupDtoFromJSON)),
    };
}

export function SectionDtoToJSON(value?: SectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'groups': ((value['groups'] as Array<any>).map(GroupDtoToJSON)),
    };
}

