/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HomeConfigurationDto } from './HomeConfigurationDto';
import {
    HomeConfigurationDtoFromJSON,
    HomeConfigurationDtoFromJSONTyped,
    HomeConfigurationDtoToJSON,
} from './HomeConfigurationDto';

/**
 * 
 * @export
 * @interface CreateOrUpdateHomeConfigurationResponse
 */
export interface CreateOrUpdateHomeConfigurationResponse {
    /**
     * 
     * @type {HomeConfigurationDto}
     * @memberof CreateOrUpdateHomeConfigurationResponse
     */
    _configuration: HomeConfigurationDto;
}

/**
 * Check if a given object implements the CreateOrUpdateHomeConfigurationResponse interface.
 */
export function instanceOfCreateOrUpdateHomeConfigurationResponse(value: object): value is CreateOrUpdateHomeConfigurationResponse {
    if (!('_configuration' in value) || value['_configuration'] === undefined) return false;
    return true;
}

export function CreateOrUpdateHomeConfigurationResponseFromJSON(json: any): CreateOrUpdateHomeConfigurationResponse {
    return CreateOrUpdateHomeConfigurationResponseFromJSONTyped(json, false);
}

export function CreateOrUpdateHomeConfigurationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrUpdateHomeConfigurationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        '_configuration': HomeConfigurationDtoFromJSON(json['configuration']),
    };
}

export function CreateOrUpdateHomeConfigurationResponseToJSON(value?: CreateOrUpdateHomeConfigurationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'configuration': HomeConfigurationDtoToJSON(value['_configuration']),
    };
}

