/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePresignedPutUrlRequest,
  CreatePresignedPutUrlResponse,
} from '../models/index';
import {
    CreatePresignedPutUrlRequestFromJSON,
    CreatePresignedPutUrlRequestToJSON,
    CreatePresignedPutUrlResponseFromJSON,
    CreatePresignedPutUrlResponseToJSON,
} from '../models/index';

export interface CreatePresignedPutUrlOperationRequest {
    createPresignedPutUrlRequest: CreatePresignedPutUrlRequest;
}

export interface DeleteFileRequest {
    uuid: string;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     */
    async createPresignedPutUrlRaw(requestParameters: CreatePresignedPutUrlOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePresignedPutUrlResponse>> {
        if (requestParameters['createPresignedPutUrlRequest'] == null) {
            throw new runtime.RequiredError(
                'createPresignedPutUrlRequest',
                'Required parameter "createPresignedPutUrlRequest" was null or undefined when calling createPresignedPutUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePresignedPutUrlRequestToJSON(requestParameters['createPresignedPutUrlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePresignedPutUrlResponseFromJSON(jsonValue));
    }

    /**
     */
    async createPresignedPutUrl(createPresignedPutUrlRequest: CreatePresignedPutUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePresignedPutUrlResponse> {
        const response = await this.createPresignedPutUrlRaw({ createPresignedPutUrlRequest: createPresignedPutUrlRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFile(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFileRaw({ uuid: uuid }, initOverrides);
    }

}
