export const friendlyFileType = (fileType: string): string => {
  if (fileType === 'application/msword') {
    return 'Document Word'
  }
  if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return 'Document Word'
  }
  if (fileType === 'application/vnd.ms-excel') {
    return 'Document Excel'
  }
  if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return 'Document Excel'
  }
  if (fileType === 'application/vnd.ms-powerpoint') {
    return 'Document PowerPoint'
  }
  if (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
    return 'Document PowerPoint'
  }
  if (fileType === 'application/pdf') {
    return 'Document PDF'
  }
  if (fileType.startsWith('image/')) {
    return 'Image'
  }

  return 'Document'
}

export const friendlyFileSize = (fileSize: number): string => {
  const ONE_KILO = 10 ** 3
  const ONE_MEGA = 10 ** 6
  const ONE_GIGA = 10 ** 9

  if (fileSize < ONE_KILO) {
    return `${fileSize} o`
  }
  if (fileSize < ONE_MEGA) {
    return `${Math.round(fileSize / ONE_KILO)} ko`
  }
  if (fileSize < ONE_GIGA) {
    return `${Math.round(fileSize / ONE_MEGA)} Mo`
  }

  return `${Math.round(fileSize / ONE_GIGA)} Go`
}
