import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { Link, useNavigate } from 'react-router-dom'
import { ConversationDto } from '../../api-client'
import FriendlyDateTime from '../../components/FriendlyDateTime'
import { useAlertService } from '../../lib/alerts'
import { useConversationsApi } from '../../lib/api-clients'
import merge from '../../lib/merge'
import plural from '../../lib/plural'

const ConversationStatus = (props: { conversation: ConversationDto }): JSX.Element => {
  const {
    conversation: { numberOfAdminUnreadMessages, numberOfOwnerUnreadMessages },
  } = props

  if (numberOfAdminUnreadMessages > 0) {
    return <strong>{plural`${numberOfAdminUnreadMessages} nouveau(x) message(s)`}</strong>
  }
  if (numberOfOwnerUnreadMessages > 0) {
    return <em>À jour</em>
  }
  return <>À jour et lu ✔</>
}

const conversationStatus = (conversation: ConversationDto): string => {
  const { numberOfAdminUnreadMessages, numberOfOwnerUnreadMessages } = conversation

  if (numberOfAdminUnreadMessages > 0) {
    return 'new-messages'
  }
  if (numberOfOwnerUnreadMessages > 0) {
    return 'up-to-date-unread'
  }
  return 'up-to-date'
}

const columns: GridColDef<ConversationDto>[] = [
  {
    field: 'displayName',
    headerName: 'Destinataire',
    width: 200,
    valueGetter: (_, conversation) => conversation.owner.displayName,
  },
  {
    field: 'lastMessageDate',
    type: 'date',
    headerName: 'Dernier message',
    width: 150,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (_, conversation) => conversation.lastMessageDate,
    renderCell: (params: GridRenderCellParams<ConversationDto>) => (
      <FriendlyDateTime date={params.row.lastMessageDate} />
    ),
  },
  {
    field: 'status',
    type: 'singleSelect',
    headerName: 'Statut',
    width: 200,
    valueOptions: [
      { value: 'new-messages', label: 'Nouveau(x) messages(s)' },
      { value: 'up-to-date-unread', label: 'À jour (mais pas encore lu)' },
      { value: 'up-to-date', label: 'À jour (et lu)' },
    ],
    valueGetter: (_, conversation) => conversationStatus(conversation),
    renderCell: (params: GridRenderCellParams<ConversationDto>) => <ConversationStatus conversation={params.row} />,
  },
]

export function ConversationTable() {
  const navigate = useNavigate()
  const alerts = useAlertService()
  const conversationsApi = useConversationsApi()

  const [conversations, setConversations] = useState<ConversationDto[]>([])

  useEffect(() => {
    async function fetchConversations(since?: Date) {
      try {
        const response = await conversationsApi.getConversations(since)
        setConversations(initial => merge(initial, response.conversations))
      } catch (error) {
        alerts.error('Erreur lors du chargement des conversations. Veuillez réessayer.')
        console.error('Error fetching data:', error)
      }
    }

    let since = new Date()
    fetchConversations()

    const intervalId = setInterval(() => {
      const now = new Date()
      fetchConversations(since)
      since = now
    }, 2000)
    return () => clearInterval(intervalId)
  }, [conversationsApi, alerts])

  const handleRowClick = (params: GridRowParams<ConversationDto>): void => {
    navigate(`/conversations/${params.row.id}`)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%', padding: 2 }}>
      <Box>
        <Typography component="strong" variant="h6">
          Conversations
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', flex: 1, width: '100%', height: '100%', minHeight: '600px', overflow: 'auto' }}>
        <DataGrid
          rows={conversations}
          columns={columns}
          onRowClick={handleRowClick}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastMessageDate', sort: 'desc' }],
            },
          }}
          sx={{ position: 'absolute', width: '100%', height: '100%' }}
        />
      </Box>
      <Box display="flex" justifyContent="end">
        <Button
          color="primary"
          size="medium"
          component={Link}
          to="/conversations/nouveau-message"
          startIcon={<EditIcon />}>
          Écrire un message
        </Button>
      </Box>
    </Box>
  )
}
