/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetDto } from './WidgetDto';
import {
    WidgetDtoFromJSON,
    WidgetDtoFromJSONTyped,
    WidgetDtoToJSON,
} from './WidgetDto';

/**
 * 
 * @export
 * @interface GroupDto
 */
export interface GroupDto {
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    title?: string;
    /**
     * 
     * @type {Array<WidgetDto>}
     * @memberof GroupDto
     */
    widgets: Array<WidgetDto>;
}

/**
 * Check if a given object implements the GroupDto interface.
 */
export function instanceOfGroupDto(value: object): value is GroupDto {
    if (!('widgets' in value) || value['widgets'] === undefined) return false;
    return true;
}

export function GroupDtoFromJSON(json: any): GroupDto {
    return GroupDtoFromJSONTyped(json, false);
}

export function GroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDtoFromJSON)),
    };
}

export function GroupDtoToJSON(value?: GroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'widgets': ((value['widgets'] as Array<any>).map(WidgetDtoToJSON)),
    };
}

