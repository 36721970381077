/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignHomeConfigurationRequest
 */
export interface AssignHomeConfigurationRequest {
    /**
     * 
     * @type {number}
     * @memberof AssignHomeConfigurationRequest
     */
    accountId: number;
}

/**
 * Check if a given object implements the AssignHomeConfigurationRequest interface.
 */
export function instanceOfAssignHomeConfigurationRequest(value: object): value is AssignHomeConfigurationRequest {
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    return true;
}

export function AssignHomeConfigurationRequestFromJSON(json: any): AssignHomeConfigurationRequest {
    return AssignHomeConfigurationRequestFromJSONTyped(json, false);
}

export function AssignHomeConfigurationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignHomeConfigurationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'],
    };
}

export function AssignHomeConfigurationRequestToJSON(value?: AssignHomeConfigurationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
    };
}

