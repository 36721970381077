import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import { HomeConfigurationDto } from '../../api-client'
import { useAlertService } from '../../lib/alerts'
import { useHomeApi } from '../../lib/api-clients'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

export interface HomeConfigurationsTableProps {
  configurations: HomeConfigurationDto[]
  refreshHomeConfigurations: () => void
}

export function HomeConfigurationsTable(props: HomeConfigurationsTableProps) {
  const { configurations, refreshHomeConfigurations } = props

  const alerts = useAlertService()
  const homeApi = useHomeApi()

  const [selection, setSelection] = useState<HomeConfigurationDto | undefined>(undefined)

  const handleMarkAsDefault = useCallback(
    (config: HomeConfigurationDto) => async () => {
      try {
        await homeApi.markHomeConfigurationAsDefault(config.id)
      } catch (error) {
        alerts.error('Erreur lors du changement de la configuration par défaut. Veuillez réessayer.')
        console.error('Error deleting home configuration:', error)
      }

      refreshHomeConfigurations()
    },
    [alerts, homeApi, refreshHomeConfigurations],
  )

  const handleEditConfiguration = useCallback(
    (config: HomeConfigurationDto) => async () => {
      setSelection(config)
    },
    [],
  )

  const handleDeleteConfiguration = useCallback(
    (config: HomeConfigurationDto) => async () => {
      if (
        !window.confirm('Êtes-vous sûr de vouloir supprimer cette configuration ? Cette opération est irréversible.')
      ) {
        return
      }

      try {
        await homeApi.deleteHomeConfiguration(config.id)
      } catch (error) {
        alerts.error('Erreur lors de la suppression de la configuration. Veuillez réessayer.')
        console.error('Error deleting home configuration:', error)
      }

      refreshHomeConfigurations()
    },
    [alerts, homeApi, refreshHomeConfigurations],
  )

  const handleCloseEditConfigurationDialog = useCallback(
    async (config: HomeConfigurationDto | undefined) => {
      if (!config) {
        setSelection(undefined)
        return
      }

      try {
        await homeApi.updateHomeConfiguration(config.id, { name: config.name, sections: config.sections })
        setSelection(undefined)
        refreshHomeConfigurations()
      } catch (error) {
        alerts.error('Erreur lors de la mise à jour de la configuration. Veuillez réessayer.')
        console.error('Error updating home configuration:', error)
      }
    },
    [alerts, homeApi, refreshHomeConfigurations],
  )

  const handleCreateConfiguration = useCallback(async () => {
    try {
      await homeApi.createHomeConfiguration({
        name: `Nouvelle configuration ${configurations.length + 1}`,
        sections: [],
      })
    } catch (error) {
      alerts.error('Erreur lors de la création de la configuration. Veuillez réessayer.')
      console.error('Error creating new home configuration:', error)
    }

    refreshHomeConfigurations()
  }, [alerts, configurations.length, homeApi, refreshHomeConfigurations])

  const renderActionsCell = useCallback(
    (params: GridRenderCellParams<HomeConfigurationDto>) => {
      const config = params.row
      return (
        <>
          <IconButton
            aria-label="Supprimer"
            title="Supprimer"
            onClick={handleDeleteConfiguration(config)}
            disabled={config.defaultConfiguration}>
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label="Utiliser par défaut"
            title="Utiliser par défaut"
            onClick={handleMarkAsDefault(config)}
            disabled={config.defaultConfiguration}>
            <StarIcon />
          </IconButton>
          <IconButton aria-label="Éditer" title="Éditer" onClick={handleEditConfiguration(config)}>
            <EditIcon />
          </IconButton>
        </>
      )
    },
    [handleDeleteConfiguration, handleEditConfiguration, handleMarkAsDefault],
  )

  const columns = useMemo(
    (): GridColDef<HomeConfigurationDto>[] => [
      {
        field: 'name',
        headerName: 'Nom de la configuration',
        width: 200,
      },
      {
        field: 'defaultConfiguration',
        headerName: '',
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<HomeConfigurationDto>) => params.value && <>(par défaut)</>,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'right',
        renderCell: renderActionsCell,
      },
    ],
    [renderActionsCell],
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%', padding: 2 }}>
      <Box>
        <Typography component="strong" variant="h6">
          Configurations
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', flex: 1, width: '100%', height: '100%', minHeight: '400px', overflow: 'auto' }}>
        <DataGrid
          rows={configurations}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          sx={{ position: 'absolute', width: '100%', height: '100%' }}
        />
      </Box>
      {selection && (
        <EditHomeConfigurationDialog configuration={selection} onClose={handleCloseEditConfigurationDialog} />
      )}
      <Box>
        <Typography>
          La configuration par défaut est celle qui sera automatiquement assignée à tous les nouveaux utilisateurs.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Button color="primary" size="medium" startIcon={<AddIcon />} onClick={handleCreateConfiguration}>
          Nouvelle configuration
        </Button>
      </Box>
    </Box>
  )
}

export interface EditHomeConfigurationDialogProps {
  configuration: HomeConfigurationDto
  onClose: (value: HomeConfigurationDto | undefined) => void
}

function EditHomeConfigurationDialog(props: EditHomeConfigurationDialogProps) {
  const { configuration, onClose } = props

  const [name, setName] = useState(configuration?.name ?? '')
  const [sections, setSections] = useState(() => JSON.stringify(configuration?.sections, null, 4))

  const valid = useMemo(() => {
    try {
      return Array.isArray(JSON.parse(sections))
    } catch {
      return false
    }
  }, [sections])

  const handleCancel = () => {
    onClose(undefined)
  }

  const handleSave = () => {
    onClose({ ...configuration, name, sections: JSON.parse(sections) })
  }

  return (
    <Dialog onClose={handleCancel} open={true}>
      <DialogTitle>Paramétrage de la configuration</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Les changements effectués ici s’appliqueront dans l’application dès la prochaine ouverture. Veuillez bien
          vérifier votre paramétrage avant de le sauvegarder. <br />
          Vous pouvez également créer une configuration de test assignée à quelques comptes exploitants pour vérifier
          les changements.
        </DialogContentText>
        <TextField
          autoFocus
          required
          name="name"
          label="Nom de la configuration"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ marginTop: 1 }}
        />
        <TextField
          required
          name="sections"
          label="Sections"
          type="text"
          value={sections}
          onChange={e => setSections(e.target.value)}
          fullWidth
          multiline
          rows={10}
          variant="standard"
          sx={{ marginTop: 1 }}
          InputProps={{
            sx: {
              font: '12px consolas, monospace',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Annuler</Button>
        <Button onClick={handleSave} disabled={!valid || !name}>
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  )
}
