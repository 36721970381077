/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbcLoginRequest
 */
export interface AbcLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AbcLoginRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AbcLoginRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof AbcLoginRequest
     */
    notificationsPushToken?: string;
}

/**
 * Check if a given object implements the AbcLoginRequest interface.
 */
export function instanceOfAbcLoginRequest(value: object): value is AbcLoginRequest {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function AbcLoginRequestFromJSON(json: any): AbcLoginRequest {
    return AbcLoginRequestFromJSONTyped(json, false);
}

export function AbcLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbcLoginRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
        'notificationsPushToken': json['notificationsPushToken'] == null ? undefined : json['notificationsPushToken'],
    };
}

export function AbcLoginRequestToJSON(value?: AbcLoginRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'password': value['password'],
        'notificationsPushToken': value['notificationsPushToken'],
    };
}

