/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePresignedPutUrlRequest
 */
export interface CreatePresignedPutUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePresignedPutUrlRequest
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePresignedPutUrlRequest
     */
    fileType: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePresignedPutUrlRequest
     */
    fileSize: number;
}

/**
 * Check if a given object implements the CreatePresignedPutUrlRequest interface.
 */
export function instanceOfCreatePresignedPutUrlRequest(value: object): value is CreatePresignedPutUrlRequest {
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    if (!('fileType' in value) || value['fileType'] === undefined) return false;
    if (!('fileSize' in value) || value['fileSize'] === undefined) return false;
    return true;
}

export function CreatePresignedPutUrlRequestFromJSON(json: any): CreatePresignedPutUrlRequest {
    return CreatePresignedPutUrlRequestFromJSONTyped(json, false);
}

export function CreatePresignedPutUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePresignedPutUrlRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'fileName': json['fileName'],
        'fileType': json['fileType'],
        'fileSize': json['fileSize'],
    };
}

export function CreatePresignedPutUrlRequestToJSON(value?: CreatePresignedPutUrlRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileName': value['fileName'],
        'fileType': value['fileType'],
        'fileSize': value['fileSize'],
    };
}

