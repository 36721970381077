import { Box, Typography } from '@mui/material'

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { HomeConfigurationDto } from '../../api-client'
import AbcChip from '../../components/AbcChip'
import { useAlertService } from '../../lib/alerts'
import { useAccountsApi, useHomeApi } from '../../lib/api-clients'

interface ConfigurableUser {
  id: number
  homeId: number
  username: string
}

export interface HomeConfigurationsAndUsersTableProps {
  configurations: HomeConfigurationDto[]
}

export function HomeConfigurationsAndUsersTable(props: HomeConfigurationsAndUsersTableProps) {
  const { configurations } = props

  const alerts = useAlertService()
  const homeApi = useHomeApi()
  const accountsApi = useAccountsApi()

  const [users, setUsers] = useState<ConfigurableUser[]>([])

  useEffect(() => {
    async function fetchAccounts() {
      try {
        const response = await accountsApi.getAccounts()
        const users = response.accounts.flatMap(({ id, abcAccount }) => (abcAccount ? [{ id, ...abcAccount }] : []))
        setUsers(users)
      } catch (error) {
        alerts.error('Erreur lors du chargement des comptes utilisateurs. Veuillez réessayer.')
        console.error('Error fetching data:', error)
      }
    }

    fetchAccounts()
  }, [alerts, accountsApi])

  const columns = useMemo(
    (): GridColDef<ConfigurableUser>[] => [
      {
        field: 'username',
        headerName: 'Identifiant ABC',
        width: 200,
        renderCell: (params: GridRenderCellParams<ConfigurableUser>) => <AbcChip username={params.value} />,
      },
      {
        field: 'homeId',
        headerName: 'Configuration',
        width: 200,
        editable: true,
        type: 'singleSelect',
        valueOptions: configurations.map(({ id, name }) => ({ value: id, label: name })),
      },
    ],
    [configurations],
  )

  const processRowUpdate = useCallback(
    async (updated: ConfigurableUser, original: ConfigurableUser): Promise<ConfigurableUser> => {
      try {
        await homeApi.assignAccount(updated.homeId, { accountId: updated.id })
        return updated
      } catch (error) {
        alerts.error('Erreur lors de l’assignation de l’utilisateur. Veuillez réessayer.')
        console.error('Error updating user:', error)
        return original
      }
    },
    [alerts, homeApi],
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%', padding: 2 }}>
      <Box>
        <Typography component="strong" variant="h6">
          Assignation des comptes
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', flex: 1, width: '100%', height: '100%', minHeight: '600px', overflow: 'auto' }}>
        <DataGrid
          rows={users}
          columns={columns}
          processRowUpdate={processRowUpdate}
          initialState={{
            sorting: {
              sortModel: [{ field: 'username', sort: 'asc' }],
            },
          }}
          sx={{ position: 'absolute', width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  )
}
