import { Box, Typography } from '@mui/material'

export function HelloPage(): JSX.Element {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: 2,
        padding: 2,
        overflow: 'hidden',
      }}>
      <Typography
        variant="h1"
        component="h1"
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: '50vh' }}>
        Bonjour
      </Typography>
      <Typography variant="subtitle1" component="p" sx={{ textAlign: 'center' }}>
        Bienvenue sur la vue exploitants de l’application <strong>TM36 Mon entreprise</strong>.
      </Typography>
      <Box sx={{ alignSelf: 'start' }}>
        <Typography variant="subtitle2" component="p">
          🌟 Nouveautés
        </Typography>
        <Typography variant="body2" component="ul" sx={{ inlineSize: 'min(100%, 800px)' }}>
          <li>
            17/01/2025&nbsp;&mdash; <strong>L’export des conversations est disponible.</strong>
          </li>
          <li>
            16/01/2025&nbsp;&mdash;{' '}
            <strong>Les indicateurs de lecture des messages sont partiellement disponibles.</strong>
            <br />
            Les conversations affichent désormais des indicateurs «&nbsp;Lu ✔&nbsp;» / «&nbsp;Pas encore lu ✘&nbsp;»
            sous chaque message. Le tableau des conversations a aussi été revu et clarifié avec ce nouveau dispositif.
            <ul>
              <li>
                Les exploitants doivent explicitement accuser réception des messages en appuyant sur le bouton
                «&nbsp;Tout marquer lu&nbsp;» d’une conversation.
              </li>
              <li>
                Les conducteurs accusent réception des messages automatiquement lorsqu’ils sont affichés dans leur
                application mobile.
              </li>
            </ul>
            ⚠️&nbsp;Attention : la mise à jour de l’application Android n’étant pas encore déployée, les messages
            envoyés aux conducteurs resteront pour le moment «&nbsp;Pas encore lu ✘&nbsp;».
          </li>
          <li>
            28/12/2024&nbsp;&mdash; <strong>L’envoi de fichiers via la messagerie est disponible.</strong>
            <br />
            Vous pouvez dorénavant envoyer des fichiers via la messagerie, que ce soit à un groupe ou dans une
            conversation.
            <br />
            ⚠️&nbsp;Attention : la mise à jour de l’application Android n’étant pas encore déployée, les fichiers
            envoyés aux utilisateurs se présenteront sous la forme d’un simple lien Web.
            <br />
            Les fichiers échangés sont stockés et hébergés sur le serveur de l’application TM36 Mon entreprise dans un{' '}
            <em>data center</em> en France.
          </li>
          <li>
            23/12/2024&nbsp;&mdash; <strong>Les vues différenciées sont disponibles.</strong>
            <br />
            Il est désormais possible, via la page <em>Configurations</em>, de créer différents paramétrages de
            l’application et d’y assigner des utilisateurs afin qu’ils aient des pages d’accueil spécifiques dans leur
            application.
          </li>
          <li>
            05/12/2024&nbsp;&mdash; <strong>La version mobile est disponible.</strong>
          </li>
        </Typography>
      </Box>
    </Box>
  )
}
