import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import NotesIcon from '@mui/icons-material/Notes'
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { ChangeEvent, memo, useState } from 'react'
import { FileInfoDto, MessageContentDto } from '../api-client'
import { useAlertService } from '../lib/alerts'
import { useFilesApi } from '../lib/api-clients'
import { friendlyFileSize } from '../lib/file-utils'

const ACCEPTED_FILE_TYPES = [
  '.doc',
  '.docx',
  '.odt',
  '.xls',
  '.xlsx',
  '.ods',
  '.ppt',
  '.pptx',
  '.odp',
  '.pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/pdf',
  'image/*',
].join(',')

const uploadFile = (putUrl: string, file: File, setProgress: (progress: number) => void): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', putUrl)

    xhr.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        setProgress((event.loaded / event.total) * 100)
      }
    })

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        resolve()
      } else {
        reject(new Error(`Upload failed: ${xhr.statusText}`))
      }
    })

    xhr.addEventListener('error', () => {
      reject(new Error('An error occurred during the upload'))
    })

    xhr.setRequestHeader('Content-Type', file.type)
    xhr.send(file)
  })
}

interface MessageInputProps {
  multiline?: boolean
  onContentChange?: (content: MessageContentDto | undefined) => void
}

interface MessageInputValue {
  text: string
  file?: {
    url: string
    info: FileInfoDto
  }
}

function MessageInput(props: MessageInputProps): JSX.Element {
  const { multiline, onContentChange } = props

  const filesApi = useFilesApi()
  const alerts = useAlertService()

  const [type, setType] = useState<'text' | 'file'>('text')
  const [value, setValue] = useState<MessageInputValue>({ text: '' })

  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleChangeType = (newType: null | 'text' | 'file') => {
    if (newType === null) {
      return
    }

    setType(newType)

    if (newType === 'text') {
      onContentChange?.({ text: value.text })
    } else if (newType === 'file') {
      if (value.file) {
        onContentChange?.({ text: value.file.url, fileInfo: value.file.info })
      } else {
        onContentChange?.(undefined)
      }
    }
  }

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value

    setValue({ ...value, text })
    onContentChange?.({ text })
  }

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length < 1) {
      return
    }

    setProgress(0)
    setUploading(true)

    try {
      const file = e.target.files[0]
      const result = await filesApi.createPresignedPutUrl({
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      })

      await uploadFile(result.putUrl, file, setProgress)

      const fileInfo: FileInfoDto = {
        fileName: file.name,
        fileType: file.type,
        sizeInBytes: file.size,
      }

      setValue({ ...value, file: { url: result.getUrl, info: fileInfo } })
      onContentChange?.({
        text: result.getUrl,
        fileInfo,
      })
    } catch (error) {
      alerts.error('Erreur lors du changement du fichier. Veuillez réessayer.')
      console.error('Error uploading file:', error)
    } finally {
      e.target.value = ''
      setUploading(false)
    }
  }

  const handleDeleteFile = () => {
    setValue({ ...value, file: undefined })
    onContentChange?.({ text: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 2,
      }}>
      <ToggleButtonGroup
        value={type}
        size={multiline ? 'medium' : 'small'}
        sx={{ alignSelf: 'end' }}
        exclusive
        onChange={(_, value) => handleChangeType(value)}
        aria-label="Type de message">
        <ToggleButton value="text" aria-label="Message texte">
          <NotesIcon />
          &nbsp;Message texte
        </ToggleButton>
        <ToggleButton value="file" aria-label="Fichier">
          <AttachFileIcon />
          &nbsp;Fichier
        </ToggleButton>
      </ToggleButtonGroup>
      {type === 'text' && (
        <TextField
          type="message"
          label="Contenu du message"
          placeholder="Votre message..."
          variant="outlined"
          size="small"
          fullWidth
          multiline
          value={value.text}
          maxRows={multiline ? undefined : 5}
          rows={multiline ? 5 : undefined}
          onChange={handleTextChange}
        />
      )}
      {type === 'file' && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: 40 }}>
          {uploading && <LinearProgress variant="determinate" value={progress} />}
          {value.file && (
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, wordBreak: 'break-word' }}>
              <CheckCircleIcon color="success" />
              <span>
                {value.file.info.fileName} &bull; {friendlyFileSize(value.file.info.sizeInBytes)}
              </span>
              <IconButton aria-label="Supprimer le fichier" title="Supprimer le fichier" onClick={handleDeleteFile}>
                <ClearIcon />
              </IconButton>
            </Typography>
          )}
          {!uploading && !value.file && (
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              size="medium"
              fullWidth
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{ flex: 1 }}>
              Choisir un fichier
              <input
                style={visuallyHidden}
                name="file-upload"
                type="file"
                accept={ACCEPTED_FILE_TYPES}
                onChange={handleUploadFile}
              />
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

export default memo(MessageInput)
