/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileInfoDto
 */
export interface FileInfoDto {
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    fileType: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    sizeInBytes: number;
}

/**
 * Check if a given object implements the FileInfoDto interface.
 */
export function instanceOfFileInfoDto(value: object): value is FileInfoDto {
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    if (!('fileType' in value) || value['fileType'] === undefined) return false;
    if (!('sizeInBytes' in value) || value['sizeInBytes'] === undefined) return false;
    return true;
}

export function FileInfoDtoFromJSON(json: any): FileInfoDto {
    return FileInfoDtoFromJSONTyped(json, false);
}

export function FileInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'fileName': json['fileName'],
        'fileType': json['fileType'],
        'sizeInBytes': json['sizeInBytes'],
    };
}

export function FileInfoDtoToJSON(value?: FileInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileName': value['fileName'],
        'fileType': value['fileType'],
        'sizeInBytes': value['sizeInBytes'],
    };
}

