import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import AbcUsersAutocomplete, { AbcAccountOption } from '../../components/AbcUsersAutocomplete'

import SendIcon from '@mui/icons-material/Send'
import { MessageContentDto } from '../../api-client'
import MessageInput from '../../components/MessageInput'
import { useAlertService } from '../../lib/alerts'
import { useConversationsApi } from '../../lib/api-clients'
import { useConfirmBeforeLeaving } from '../../lib/useConfirmBeforeLeaving'
import { useResetKey } from '../../lib/useResetKey'

export function GroupMessage() {
  const alerts = useAlertService()
  const conversationsApi = useConversationsApi()

  const [recipients, setRecipients] = useState<AbcAccountOption[]>([])
  const [content, setContent] = useState<MessageContentDto>()
  const [sending, setSending] = useState(false)

  const [contentKey, resetContentKey] = useResetKey()

  const unsavedChanges = !!content?.text || sending
  const sendingDisabled = content === undefined || recipients.length === 0 || sending

  useConfirmBeforeLeaving(unsavedChanges)

  const handleSend = async () => {
    if (!content) {
      return
    }

    setSending(true)
    try {
      await conversationsApi.postMessageToGroup({
        content,
        recipientUserAccountIds: recipients.map(acc => acc.id),
      })
      alerts.success('Le message a été envoyé.')

      setContent(undefined)
      resetContentKey()
    } catch (e) {
      alerts.error('Erreur lors de l’envoi du message. Veuillez réessayer.')
      console.error('Could not send message', e)
    } finally {
      setSending(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2,
      }}>
      <Typography component="strong" variant="h6">
        Écrire un nouveau message
      </Typography>
      <AbcUsersAutocomplete label="Destinataires" value={recipients} onChange={setRecipients} />
      <MessageInput key={contentKey} multiline onContentChange={setContent} />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end ' }}>
        <Button variant="contained" endIcon={<SendIcon />} onClick={handleSend} disabled={sendingDisabled}>
          Envoyer
        </Button>
      </Box>
    </Box>
  )
}
