import DownloadIcon from '@mui/icons-material/Download'
import SendIcon from '@mui/icons-material/Send'
import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { grey, lightBlue } from '@mui/material/colors'
import { compareAsc, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import Linkify from 'linkify-react'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { AccountDto, ConversationDto, FileInfoDto, MessageContentDto, MessageDto } from '../../api-client'
import MessageInput from '../../components/MessageInput'
import { useAlertService } from '../../lib/alerts'
import { useConversationsApi } from '../../lib/api-clients'
import { friendlyFileSize, friendlyFileType } from '../../lib/file-utils'
import merge from '../../lib/merge'
import { useConfirmBeforeLeaving } from '../../lib/useConfirmBeforeLeaving'
import { useResetKey } from '../../lib/useResetKey'

const formatMessageDate = (date: Date): string => {
  return format(date, 'PPPPp', { locale: fr })
}

const formatReadDate = (date: Date): string => {
  return format(date, 'PPp', { locale: fr })
}

const byDateAsc = (a: MessageDto, b: MessageDto): number => {
  return compareAsc(a.createdDate, b.createdDate)
}

const TextMessage = memo(function TextMessage(props: { message: MessageDto }): JSX.Element {
  return (
    <Typography component="p" variant="body2" style={{ whiteSpace: 'pre-line' }}>
      <Linkify as={React.Fragment}>{props.message.content}</Linkify>
    </Typography>
  )
})

const FileMessage = memo(function FileMessage(props: { uri: string; fileInfo: FileInfoDto }): JSX.Element {
  const { uri, fileInfo } = props
  return (
    <>
      {fileInfo.fileType.startsWith('image/') && (
        <img
          src={uri}
          alt={fileInfo.fileName}
          style={{
            maxWidth: 160,
            maxHeight: 120,
            objectFit: 'contain',
          }}
        />
      )}
      <Typography variant="body2" style={{ wordBreak: 'break-word' }}>
        <a href={uri} target="_blank" rel="noreferrer noopener">
          {fileInfo.fileName}
        </a>
        <br />
        {friendlyFileType(fileInfo.fileType)} &bull; {friendlyFileSize(fileInfo.sizeInBytes)}
      </Typography>
    </>
  )
})

interface MessageProps {
  message: MessageDto
  previous: MessageDto | undefined
  ownerId: number
}

const Message = memo(function Message(props: MessageProps) {
  const { message, previous, ownerId } = props

  const [previousDate, currentDate] = useMemo(
    () => [previous ? formatMessageDate(previous.createdDate) : null, formatMessageDate(message.createdDate)],
    [previous, message],
  )

  const messageStyle = {
    padding: 1,
    borderRadius: 4,
    marginBottom: 1,
  }

  const messageReceivedStyle = {
    ...messageStyle,
    alignSelf: 'flex-start',
    marginRight: '25%',
    bgcolor: grey[100],
  }
  const messageSentStyle = {
    ...messageStyle,
    marginLeft: '25%',
    alignSelf: 'flex-end',
    bgcolor: lightBlue[100],
  }

  return (
    <>
      {currentDate !== previousDate && (
        <Box sx={{ padding: 1, textAlign: 'center' }}>
          <Typography variant="caption">{currentDate}</Typography>
        </Box>
      )}
      <Box sx={message.author.id === ownerId ? messageReceivedStyle : messageSentStyle}>
        <Typography variant="subtitle2" component="p">
          {message.author.displayName}
        </Typography>

        {message.fileInfo ? (
          <FileMessage uri={message.content} fileInfo={message.fileInfo} />
        ) : (
          <TextMessage message={message} />
        )}

        <Typography variant="caption" component="p" color={grey[600]} textAlign="right">
          {message.readDate ? <>Lu le {formatReadDate(message.readDate)} ✔</> : <>Pas encore lu ✘</>}
        </Typography>
      </Box>
    </>
  )
})

interface MessageListProps {
  owner: AccountDto
  messages: MessageDto[]
}

const MessageList = memo(function MessageList(props: MessageListProps) {
  const { owner, messages } = props

  const lastMessageRef = useRef<MessageDto>()
  const bottomRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const currentLastMessage = lastMessageRef.current
    const newLastMessage = messages.at(-1)

    if (currentLastMessage !== newLastMessage) {
      bottomRef.current?.scrollIntoView()
    }

    lastMessageRef.current = newLastMessage
  }, [messages])

  return (
    <Box flex={1} position="relative" minHeight="400px">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 2,
          overflow: 'scroll',
        }}>
        {messages.map((message, index) => (
          <Message key={message.id} message={message} previous={messages[index - 1]} ownerId={owner.id} />
        ))}
        <Typography ref={bottomRef} component="span" variant="overline" padding={4} alignSelf="center">
          Fin des messages
        </Typography>
      </Box>
    </Box>
  )
})

interface ConversationThreadProps {
  conversationId: number
}

export const ConversationThread = memo(function ConversationThread(props: ConversationThreadProps) {
  const { conversationId } = props

  const alerts = useAlertService()
  const conversationsApi = useConversationsApi()

  const [conversation, setConversation] = useState<ConversationDto | undefined>(undefined)
  const [messages, setMessages] = useState<MessageDto[]>([])

  const [content, setContent] = useState<MessageContentDto>()
  const [sending, setSending] = useState(false)

  const [contentKey, resetContentKey] = useResetKey()

  const unsavedChanges = !!content?.text || sending
  const sendingDisabled = content === undefined || sending

  useConfirmBeforeLeaving(unsavedChanges)

  useEffect(() => {
    async function fetchConversation(since?: Date) {
      if (!conversationId) {
        return
      }

      try {
        const response = await conversationsApi.getConversation(conversationId, since)

        setConversation(response.conversation)
        setMessages(initial => merge(initial, response.conversation.messages).sort(byDateAsc))
      } catch (error) {
        alerts.error('Erreur lors du chargement de la conversations. Veuillez réessayer.')
        console.error('Error fetching data:', error)
      }
    }

    let since = new Date()
    fetchConversation()

    const intervalId = setInterval(() => {
      const now = new Date()
      fetchConversation(since)
      since = now
    }, 2000)
    return () => clearInterval(intervalId)
  }, [alerts, conversationsApi, conversationId])

  if (!conversation) {
    return null
  }

  const handleSend = async () => {
    if (!content) {
      return
    }

    setSending(true)
    try {
      const response = await conversationsApi.postMessageIntoConversation(conversationId, { content })
      alerts.success('Le message a été envoyé.')

      setMessages(initial => merge(initial, [response.message]))
      setContent(undefined)
      resetContentKey()
    } catch (e) {
      alerts.error('Erreur lors de l’envoi du message. Veuillez réessayer.')
      console.error('Could not send message', e)
    } finally {
      setSending(false)
    }
  }

  const handleMarkAsRead = async () => {
    await conversationsApi.markConversationAsReadByAdmin(conversationId)
  }

  const handleDownload = async () => {
    const result = await conversationsApi.exportConversation(conversationId)

    const anchor = document.createElement('a')
    anchor.href = window.URL.createObjectURL(new Blob([result], { type: 'text/csv' }))
    anchor.download = `Conversation avec ${conversation.owner.displayName}.csv`

    document.body.append(anchor)
    anchor.click()
    anchor.remove()
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography component="strong" variant="h6">
          Conversation avec {conversation.owner.displayName}
        </Typography>
        <Tooltip title="Télécharger la conversation au format CSV">
          <IconButton color="primary" aria-label="Télécharger la conversation au format CSV" onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Box sx={{ flex: 1 }}></Box>
        <Tooltip title="Marquer tous les messages entrants comme étant lus">
          <Button variant="text" onClick={handleMarkAsRead}>
            Tout marquer lu
          </Button>
        </Tooltip>
      </Box>
      <Divider />
      <MessageList owner={conversation.owner} messages={messages} />
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, alignItems: 'stretch' }}>
        <MessageInput key={contentKey} onContentChange={setContent} />
        <Button
          color="primary"
          endIcon={<SendIcon />}
          sx={{ alignSelf: 'end' }}
          disabled={sendingDisabled}
          onClick={handleSend}>
          Envoyer
        </Button>
      </Box>
    </Box>
  )
})
