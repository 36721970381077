/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileInfoDto } from './FileInfoDto';
import {
    FileInfoDtoFromJSON,
    FileInfoDtoFromJSONTyped,
    FileInfoDtoToJSON,
} from './FileInfoDto';

/**
 * 
 * @export
 * @interface MessageContentDto
 */
export interface MessageContentDto {
    /**
     * 
     * @type {string}
     * @memberof MessageContentDto
     */
    text: string;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MessageContentDto
     */
    fileInfo?: FileInfoDto;
}

/**
 * Check if a given object implements the MessageContentDto interface.
 */
export function instanceOfMessageContentDto(value: object): value is MessageContentDto {
    if (!('text' in value) || value['text'] === undefined) return false;
    return true;
}

export function MessageContentDtoFromJSON(json: any): MessageContentDto {
    return MessageContentDtoFromJSONTyped(json, false);
}

export function MessageContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'],
        'fileInfo': json['fileInfo'] == null ? undefined : FileInfoDtoFromJSON(json['fileInfo']),
    };
}

export function MessageContentDtoToJSON(value?: MessageContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'fileInfo': FileInfoDtoToJSON(value['fileInfo']),
    };
}

